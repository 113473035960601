
  import { Component, Prop } from 'vue-property-decorator'
  import { FilesProcess } from '@/components/forms/view/FilesProcess'
  import { Stock } from '@/entities/sales'
  import JSZip from 'jszip'
  import axios from 'axios'

  const baseURl = process.env.VUE_APP_BASE_URL
@Component
  export default class DownloadPhotos extends FilesProcess {
  @Prop({ type: Stock, required: true }) stock!: Stock

  loading = false

  // 1. Función para descargar la imagen a través de tu file-proxy y devolver arrayBuffer
  async fetchFromProxy (url: string): Promise<ArrayBuffer | null> {
    try {
      const encodedUrl = encodeURIComponent(url)
      const uri = `${baseURl}/file-proxy/get-image?url=${encodedUrl}`

      const response = await axios.get(uri, {
        responseType: 'arraybuffer',
      })

      return response.data // arrayBuffer
    } catch (error) {
      console.error('Error al cargar la foto:', error)
      return null
    }
  }

  // 2. Método principal para descargar todas las fotos
  async downloadPhotos () {
    try {
      this.loading = true
      const photos = await this.getPhotos(this.stock.id)

      const zip = new JSZip()

      for (const photo of photos) {
        // Ajusta la ruta según la estructura de tu objeto
        const url = photo?.file?.metadata?.l?.uri || photo?.file?.metadata?.uri
        if (!url) continue

        // Descarga con tu file-proxy
        const data = await this.fetchFromProxy(url)
        if (!data) continue

        const filename = `${photo.parameter.description}.jpg`
        zip.file(filename, data)
      }

      // Generar Blob final del ZIP
      const contenidoZip = await zip.generateAsync({ type: 'blob' })

      // Crear link para descargar
      const link = document.createElement('a')
      link.href = URL.createObjectURL(contenidoZip)
      link.download = `${this.stock.auto.name}.zip`
      link.click()
      URL.revokeObjectURL(link.href)
    } catch (error) {
      console.error('Error al generar el ZIP:', error)
    } finally {
      this.loading = false
    }
  }

  // 3. Obtener array de fotos
  async getPhotos (id: number) {
    if (!id) return []

    const filter = {
      _and: [
        { process: { table_name: { _eq: 'stock' } } },
        { file_type: { name: { _eq: 'photo' } } },
      ],
    }

    const photos = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter,
      force: true,
    })

    // Ordenar por "visibility.publications.order"
    const fileParametersPhoto = photos.sort(
      (a, b) => a.visibility?.publications?.order - b.visibility?.publications?.order
    )

    const newPhotos = []
    for (const parameter of fileParametersPhoto) {
      const file = await this.handleComponentChange(parameter, id)
      if (file?.length) {
        newPhotos.push(...file)
      }
    }
    return newPhotos
  }

  async handleComponentChange (parameter: any, id: number) {
    const photosFetched = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: id } },
          { id_file_parameter: { _eq: parameter.id } },
        ],
      },
      force: true,
    })

    return photosFetched.length ? photosFetched : []
  }
  }
